var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "graphTitle",
        { staticClass: "title", attrs: { title: "出入场车流量分析" } },
        [
          _c(
            "el-tooltip",
            {
              attrs: {
                placement: "top",
                content:
                  "展示所选日期内各时段的车辆出入场数量，用来分析一天内入场车流量与出场车流量的变化情况",
              },
            },
            [_c("i", { staticClass: "el-icon-question my-icon" })]
          ),
        ],
        1
      ),
      _c("div", {
        staticClass: "circularChart",
        attrs: { id: "circularChart" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }